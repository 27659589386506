<template>
    <div>
        <b-row>
            <b-col sm="11">
                <InputText title="Nota" field="comment" :enter="addNote" v-model="comment" />
            </b-col>
            <b-col sm="1">
                <div class="div-btn">
                    <Button _key="btnAdd" type="primary" :disabled="comment == ''" classIcon="fa-sharp fa-solid fa-plus"
                        size="small" :clicked="addNote" />
                </div>
            </b-col>
        </b-row>
        <Loading v-if="loading" type="line" />
        <ScrollBar :minHeight="300" :maxHeight="300">
            <div v-if="!loading" class="div-comments">
                <div v-for="comment in comments">
                    <b-row>
                        <b-col sm="1">
                            <div class="text-right">
                                <img :src="comment.avatar" class="user-photo">
                            </div>
                        </b-col>
                        <b-col sm="10">
                            <small> {{ comment.userName }} em {{ comment.registeredDateStr }} </small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <div v-if="comment.id != commentSelected.id" class="div-comment" @click="edit(comment)">
                                {{ comment.comment }}
                            </div>
                            <div v-else>
                                <b-row>
                                    <b-col sm="10">
                                        <InputText :enter="updateNote" v-model="comment.comment" />
                                    </b-col>
                                    <b-col sm="2">
                                        <div class="side-by-side div-btn">
                                            <div class="div-icon-save" @click="updateNote">
                                                <i class="fa-solid fa-floppy-disk"></i>
                                            </div>
                                        </div>
                                        <div class="side-by-side div-btn">
                                            <div class="div-icon-cancel" @click="commentSelected = {}">
                                                <i class="fa-solid fa-ban"></i>
                                            </div>
                                        </div>
                                        <div class="side-by-side div-btn">
                                            <div class="title-remove div-icon-cancel" @click="remove(comment)">
                                                <i class="fa-solid fa-trash"></i>
                                            </div>
                                        </div>

                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div v-if="comments.length == 0">Nenhuma nota adicionada!</div>
        </ScrollBar>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "NoteListCreateEdit",
    props: ["jobId"],
    components: {
        Button,
        InputText,
        ScrollBar,
        Loading
    },
    data() {
        return {
            comment: "",
            commentSelected: "",
            comments: [],
            urlCreate: "/api/v1/shared/note/create",
            urlUpdate: "/api/v1/shared/note/update",
            urlDelete: "/api/v1/shared/note/delete",
            urlGetAll: "/api/v1/shared/note/get-all",
            loading: true,
        }
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi", "deleteAllApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        getAll() {
            let params = {
                url: this.urlGetAll,
                obj: {
                    any: this.jobId,
                }
            };
            this.getApi(params).then((response) => {
                this.comments = response.content.data;
                this.loading = false;
            });
        },
        addNote() {
            let params = {
                url: this.urlCreate, obj: {
                    comment: this.comment,
                    jobId: this.jobId,
                    userId: this.userLogged.user.id
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.comment = "";
                    this.getAll();
                }
                this.removeLoading(["btnAdd"]);
            });
        },
        updateNote() {
            let params = {
                url: this.urlUpdate, obj: {
                    id: this.commentSelected.id,
                    comment: this.commentSelected.comment,
                },
                notNotifyToast: true
            };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.commentSelected = {};
                    this.getAll();
                }
            });
        },
        remove(item) {
            let params = {
                url: this.urlDelete,
                selected: [item.id],
            };
            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.getAll();
                }
            });
        },
        edit(comment) {
            this.commentSelected = comment;
        }
    }
}

</script>
<style scoped>
.div-btn {
    margin-top: 36px;
}

.user-photo {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-bottom: 3px;
}

.div-comments {
    margin-top: 20px;
}

.div-comment {
    margin-left: 67px;
    margin-right: 15px;
    margin-bottom: 30px;
    padding: 5px;
    font-size: 13px;
    color: #535252;
    background-color: #F0F0F0;
    border-radius: 10px;
    cursor: pointer;
}

.div-btn {
    margin-left: 10px;
}

.div-icon-save {
    color: darkgreen;
    font-size: 20px;
    cursor: pointer;
}

.div-icon-cancel {
    color: red;
    font-size: 20px;
    cursor: pointer;
}

.title-remove {
    color: red;
    cursor: pointer;
}
</style>