<template>
    <div>
        <Loading type="line" :center="false" v-show="loading" />
        <ScrollBar id="items-rent" :minHeight="300" :maxHeight="300">
            <table class="table table-responsive-xs">
                <thead>
                    <tr>
                        <th>
                            <span class="title-header"> Endereço </span>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="item in content.data">
                    <tr>
                        <td class="td-product">
                            <span>
                                {{ item.description }} - {{ item.address }}
                            </span>
                        </td>

                    </tr>
                </tbody>
                <tbody v-show="content.totalRecords == 0">
                    <tr>
                        <td colspan="12">
                            <span class="title">Nenhum registro encontrado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ScrollBar>
    </div>
</template>

<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";


import { mapGetters, mapActions } from "vuex";

export default {
    name: "AddressRentList",
    props: {
        rentId: String,
    },
    components: {
        ScrollBar,
        TableTotalRecords,
        Loading
    },
    data() {
        return {
            urlGetApi: "/api/v1/rental/address-rent/get-all",
            data: [],
            item: {},
            loading: true,
            content: {
                data: [],
                totalRecords: 0,
            },
            baseParams: {
                search: undefined,
                filter: "contains",
                currentPage: 1,
                totalPerPage: 20,
            },
        };
    },
    mounted() {
        this.getAll(this.rentId);
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll(rentId) {
            this.loading = true;
            let params = {
                url: this.urlGetApi,
                obj: {
                    ...this.baseParams,
                    any: rentId,
                    noPaginated: true,
                },
            };
            this.getApi(params).then((response) => {
                this.content.data = response.content.data;
                this.content.totalRecords = response.content.totalRecords;
                this.loading = false;
            });
        },
    },
    watch: {
        rentId: {
            handler() {
                this.getAll(this.rentId);
            },
            deep: true,
        },
    },
}


</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}
</style>
