export default class Job {
    constructor() {
        this.id = "";
        this.subject = "";
        this.description = "";
        this.location = "";
        this.dateStart = "";
        this.timeStart = "";
        this.dateEnd = "";
        this.timeEnd = "";
        this.allDay = false;
        this.done = false;
        this.userId = "";
        this.guest = [];
        this.organizerName = "";
        this.organizerEmail = "";
        this.notify = false;
    }
    update(data) {
        this.id = data.id;
        this.subject = data.subject;
        this.description = data.description;
        this.location = data.location;
        this.dateStart = data.dateStart;
        this.timeStart = data.timeStart;
        this.dateEnd = data.dateEnd;
        this.timeEnd = data.timeEnd;
        this.allDay = data.allDay;
        this.done = data.done;
        this.userId = data.userId;
        this.guest = data.listGuest;
    }
}