<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <b-tabs v-show="!loading" v-if="!showPrint">
      <b-tab title="Dados" v-if="!onlyPrint">
        <b-row>
          <b-col sm="2">
            <div class="title text-right">Identificação</div>
          </b-col>
          <b-col sm="4">
            <span class="title">
              {{ content.identification }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <div class="title text-right">Cliente</div>
          </b-col>
          <b-col sm="10">
            <span class="title">
              {{ content.customerSupplier.person.companyName }} -
              {{ content.customerSupplier.person.tradeName }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <div class="title text-right">Solicitante</div>
          </b-col>
          <b-col sm="4">
            <span class="title">
              {{ content.contact.name }}
              <span v-if="content.contact.mobile">
                - {{ content.contact.mobile }}
              </span>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <div class="title text-right">Observações</div>
          </b-col>
          <b-col sm="10">
            <span class="title" v-html="content.observationServiceOrder"></span>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Produtos" v-show="!onlyPrint" @click="showProducts = true">
        <ItemsRentList v-if="showProducts" :rentId="rentId" :periodRentId="periodRentId" />
      </b-tab>
      <b-tab title="Saída/Retorno" v-if="!onlyPrint">
        <AddressRentList :rentId="rentId" />
      </b-tab>
    </b-tabs>
    <br />
    <div class="text-center">
      <Button _key="btnPrint" type="info" title="Compartilhar" classIcon="fa-light fa-share-nodes" size="small"
        :clicked="print" />
    </div>
    <br>
    <div class="text-center div-button-moviment" v-if="hasRule('Operational')">
      <ButtonMovimentStock :rentId="rentId" :periodRentId="periodRentId" />
    </div>
  </div>
</template>

<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ItemsRentList from "./ItemsRentList.vue";
import AddressRentList from './AddressRentList.vue'
import ButtonMovimentStock from "./ButtonMovimentStock.vue";
import PrintPreview from '../adm/document/PrintPreview.vue'

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "OperationalRent",
  components: { Button, Loading, ItemsRentList, ButtonMovimentStock, AddressRentList, PrintPreview },
  props: {
    module: String,
    rentId: String,
    periodRentId: String,
    onlyPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlGetById: "/api/v1/rental/rent/get-by-id",
      content: {},
      loading: true,
      showProducts: false,
    };
  },
  mounted() {
    this.getById();
  },
  computed: {
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.rentId } };
      this.loading = true;
      this.getApi(params).then((response) => {
        this.content = response.content;
        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 100);
      });
    },
    print() {
      this.addEvent({ name: "print" });
      this.removeLoading(["btnPrint"]);
    }
  },
};
</script>
<style scoped>
.title {
  font-weight: bold;
}

.div-button-moviment {
  margin-bottom: 10px;
}
</style>